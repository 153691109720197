@import "./assets/scss/main.scss";

:where(.css-dev-only-do-not-override-1xg9z9n).ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-header-text {
  margin-inline-end: auto;
  margin-left: -13px !important;
}

.billing {
  input {
    border: 1px solid rgba(191, 191, 191, 1);
    background: transparent !important;
    font-size: 16px !important;
  }

  textarea {
    border: 1px solid rgba(191, 191, 191, 1);
    background: transparent !important;
    font-size: 16px !important;
  }



  .frm {
    label {
      background: #F7F7F7 !important;
    }
  }

  .accordian .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 10px 20px !important;
    padding-bottom: 20px;
  }

  .ant-picker {
    background: transparent !important;
    font-size: 16px !important;
  }

  .ant-picker-input {

    background: transparent !important;
  }
}

.login {
  background-image: url(./assets/rect.png);
  background-size: cover;
  height: 100vh;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

textarea {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
}

input {
  outline: none;
}

.login-page {
  border-radius: 30px;
  border: 1px solid #848484;
  background: #FBFBFB;
  box-shadow: 0px 4px 11.6px 0px rgba(0, 0, 0, 0.19);
  padding: 40px;
  background-image: url(./assets/goog.png);
  background-repeat: no-repeat;
  background-position: right;
  align-items: baseline;

  h1 {
    color: #4285F4;
    font-family: Poppins;
    font-size: 46px;
    font-style: normal;
    font-weight: 400;
    line-height: 117.255%;
    /* 53.937px */
    padding-top: 100px;
    padding-bottom: 100px;
    letter-spacing: 0.23px;
  }


  .google {
    border-radius: 50.717px;
    border: 1.585px solid #D8D8D8;
    background: #FFF;
    box-shadow: 0px 4px 27.2px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    align-items: center;
    width: 390.568px;
    height: 101.434px; //0ld
    // height: 60px;
    // @media(max-width:1800px) {

    //   width: 395px;
    //   height: 80px;
    //   font-size: 20px;
    // }

    flex-shrink: 0;
    justify-content: center;
    color: #050505;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.143px;
    margin-bottom: 15px;

    img {
      padding-right: 20px;
      width: 50px;
    }

  }

  .akku {
    border-radius: 50.717px;
    border: 1.585px solid #D8D8D8;
    background: #FFF;
    box-shadow: 0px 4px 27.2px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    align-items: center;
    width: 390.568px;
    // height: 101.434px; old
    height: 60px;

    // @media(max-width:1800px) {
    //   width: 395px;
    //   height: 80px;
    //   font-size: 20px;
    // }

    flex-shrink: 0;
    justify-content: center;
    color: #050505;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.143px;

    img {
      //padding-right: 20px;
      width: 40px;
    }

  }
}

.app-theme .layout {
  background-repeat: no-repeat;
  background-size: contain;
}

.nav {
  background: #FFF;
  box-shadow: 0px 4px 25.6px 0px rgba(0, 0, 0, 0.12);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

.bg-gy1 {
  border-radius: 18px;
  border: 1px solid #C7C7C7;
  background: #F7F7F7;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 27px;

  label {
    position: absolute;
    top: -6px;
    // background-color: #fff;
    background-color: #F7F7F7;
    z-index: 3;
    margin-left: 10px;
  }
}

h2 {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: -0.108px;
}

.rounded-full {
  width: 60px;
}


.nav-name {
  color: #000;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: -0.108px;
}

.ant-table-content {
  height: calc(100vh - 341px);
  overflow-y: auto;
  padding-right: 10px;
  // border-radius: 16px;
  border-radius: 11px 8px 8px 11px;
  border: 1px solid #C7C7C7;
  background: #FFF;
  padding-top: 10px;
}

.ant-table-cell {
  background-color: #fff !important;
}

.al-ser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.al-ser1 {
  display: flex;
  justify-content: end;
  align-items: center;
}

.int {
  border-radius: 58px;
  border: 1px solid #C6C6C6;
  background: #FFF;
  width: 450px;

  input {
    padding-left: 8px;
  }
}

.n-user {
  cursor: pointer !important;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #BD44CA;
  background: #BD44CA;
  margin-left: 8px;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #FFF;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  padding: 13px 20px;
}

.n-user-newprop {
  cursor: pointer;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #BD44CA;
  background: #BD44CA;
  margin-left: 8px;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  padding: 22px 14px;
  padding-right: 20px;

  &:hover {
    // background-color: white;
    background: #BD44CA !important;
    border: 1px solid #BD44CA !important;
    color: #FFF !important;
    // border-color: #BD44CA !important;
    // color: #BD44CA !important;
  }
}


.n-user-quote {
  cursor: pointer;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #BD44CA;
  background: #BD44CA;
  margin-left: 8px;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  // padding: 13px 20px;
}

.max-w-screen-2xl {
  max-width: 1680px;
}

.accordian-screen {
  max-width: 1760px;
}

.bck {
  color: #1D1D1D;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 20.462px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 8px;
  padding-bottom: 15px;
  width: 100px;
  justify-content: space-between;
  align-items: center;
}

h3 {
  color: #BD44CA;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

.version-history-title {
  color: #BD44CA;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;

}

.date {
  color: #0A0A0A;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 100% */
  letter-spacing: -0.07px;
}

.amf {

  padding: 10px 10px 10px 10px;

  &:hover {
    background: #EAEAEA;
  }
}

.amf1 {
  padding: 10px 10px 10px 10px;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
  color: var(--Neutral-700, #6A6A65);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 87.5% */
  letter-spacing: -0.08px;
}

.editk {
  border-radius: 4px;
  background: #F7F7F7;
  // padding: 5px;
  font-size: 16px;
}

.name {
  // padding: 5px;
  font-size: 16px;
}

.ant-table-wrapper .ant-table-cell,
.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td,
.ant-table-wrapper tfoot>tr>th,
.ant-table-wrapper tfoot>tr>td {
  color: #6A6A65;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 87.5% */
  letter-spacing: -0.08px;
  padding-left: 40px;
}

.tot {
  color: #0A0A0A;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 100% */
  letter-spacing: -0.07px;
  text-align: right;
}

.edi {
  color: #1F1F1F;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: -0.084px;
}

.cun {
  color: #595959;
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  /* 116.667% */
  letter-spacing: -0.06px;
}

// .bg-gy {
//   border-radius: 14px;
//   border: 1px solid #D9D9D9;
//   background: #FFF;
//   box-shadow: 0px 0px 47.5px 0px rgba(0, 0, 0, 0.12);
//   padding: 25px;
// }
.bg-gy {
  border-radius: 16px;
  border: 1px solid #C7C7C7;
  background: #F7F7F7;
  padding: 30px;

}

.ant-modal-footer {
  .ant-btn-default {
    display: none;
  }
}

.frm {
  position: relative;

  input {
    border-radius: 5px;
    font-size: 16px !important;
    border: 1px solid #D9D9D9;
    background: #FFF;
    // box-shadow: 0px 0px 47.5px 0px rgba(0, 0, 0, 0.12);
    height: 54px;
  }



  label {
    position: absolute;
    background: transparent;
    padding: 2px;
    left: 8px;
    top: -10px;
    color: #969696;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    background: #fff;
    /* 16px */
  }
}

.frm2 {
  position: relative;
  margin-top: 30px;

  input {
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    color: #000;
    height: 54px;
    font-size: 16px;
  }
}

.selected {
  position: relative;

  input {
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    background: inherit;
    height: 54px;
    font-size: 16px;
  }
}

.selected2 {
  position: relative;
  margin-top: 30px;

  input {
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    background: inherit;
    height: 54px;
    font-size: 16px;
  }
}

.frm1 {
  position: relative;
  margin-top: 30px;

  input {
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 16px;
    // background: #FFF;
    background: #F7F7F7 !important;
    // box-shadow: 0px 0px 47.5px 0px rgba(0, 0, 0, 0.12);
    height: 54px;
  }


  .lab1 {
    position: absolute;
    background: transparent;
    padding: 2px;
    right: 179px;
    top: -10px;
    color: #969696;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    background: #fff;
  }

  .lab {
    position: absolute;
    background: transparent;
    padding: 2px;
    left: 8px;
    top: -10px;
    color: #969696;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    background: #fff;
  }

  .lab2 {
    position: absolute;
    background: transparent;
    padding: 2px;
    right: 190px;
    top: -10px;
    color: #969696;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    background: #fff;
  }

  .lab3 {
    position: absolute;
    background: transparent;
    padding: 2px;
    right: 160px;
    top: -10px;
    color: #969696;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    background: #fff;
  }
}

h4 {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
  margin-top: 10px;

}

#countries {
  stroke-width: 0.79px;
  stroke: #B5B5B5;
  height: 54px;
  background: #fff;
  border: 1px solid #D9D9D9;
  background: #FFF;
  // box-shadow: 0px 0px 47.5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.dwn {
  display: flex;
  justify-content: center;
  margin: 25px;
}

.dow {
  border-radius: 33.666px;
  background: #BD44CA;
  display: flex;
  width: 224px;
  height: 50px;
  padding: 13.534px 20px;
  justify-content: center;
  align-items: center;
  gap: 13.534px;
  flex-shrink: 0;
  color: #fff;
}

.lab4 {
  position: absolute;
  top: 65px;
  left: 10px;
  background: #fff;
}

.dow1 {
  border-radius: 33.666px;
  background: #BD44CA;
  display: flex;
  font-weight: 600;
  height: 50px;
  padding: 13px 45px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-shrink: 0;
  color: #fff;
  display: flex;
}

#message {
  border: 1px solid #D9D9D9;
  background: #FFF;
  border-radius: 5px;
}

#tran {
  stroke-width: 0.79px;
  cursor: pointer;
  stroke: #B5B5B5;
  height: 54px;
  // background: #fff;
  border: 1px solid #D9D9D9;
  background: #F7F7F7;
  // background: #FFF;
  // box-shadow: 0px 0px 47.5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  outline: none;
}


#peri {
  cursor: pointer;
  stroke-width: 0.79px;
  stroke: #B5B5B5;
  height: 54px;
  // background: #fff;
  border: 1px solid #D9D9D9;
  background: #F7F7F7;
  // box-shadow: 0px 0px 47.5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  outline: none;
}

.peri {
  stroke-width: 0.79px;
  stroke: #B5B5B5;
  height: 54px;
  // background: #fff;
  border: 1px solid #D9D9D9;
  background: #F7F7F7;
  // box-shadow: 0px 0px 47.5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

input[type="checkbox"]:checked {
  accent-color: #BD44CA;
  background-color: #BD44CA;
  border-radius: 5px;
}

label {
  color: #969696;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 16px */
}

.n-user1 {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid #BD44CA;
  background: #BD44CA;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #FFF;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  padding: 9px 18px;
}

.tabs {
  overflow-x: auto;
  // border: 1px solid #C7C7C7; 
  // border-radius: 13px;

  .ant-table-thead>tr>th,
  .ant-table-thead>tr>td {
    color: var(--Neutral-700, #6A6A65);
    font-family: " Poppins", sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 100% */
    letter-spacing: -0.07px;
  }



  .ant-table-tbody>tr>th {
    word-wrap: break-word;
    word-break: break-all;
  }

  .ant-table-content {
    height: calc(45vh - 100px);
  }

  .ant-pagination {
    display: none;
  }
}

.n-user3 {
  display: flex;
  width: 141px;
  height: 43px;
  padding: 13.23px 21.168px;
  justify-content: center;
  align-items: center;
  gap: 10.584px;
  border-radius: 10.584px;
  border-radius: 39.69px;
  border: 1.323px solid #BD44CA;
  box-shadow: 0px 1.323px 2.646px 0px rgba(16, 24, 40, 0.05);
  color: #BD44CA;
  cursor: pointer;
}

.jsk {
  justify-content: space-between;
}

.flx {
  display: flex;
  align-items: center;
  justify-content: center;


  .dow1 {
    margin: 10px;
    cursor: pointer;
  }
}


.accordian {
  .ant-collapse-header-text {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    position: absolute;
    right: 20px;
  }

  .ant-collapse .ant-collapse-content {
    border: none;
  }

  .ant-collapse .ant-collapse-content {
    background: none;

  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    border-radius: 13px;
    border: none;
    padding: 20px;
    background: #F7F7F7;
  }

  .ant-collapse>.ant-collapse-item {
    border: none;
    border: none;
  }

  .ant-collapse {
    // border: none;
    margin-bottom: 15px;
    border-radius: 13px;
    border: 1px solid #C7C7C7;

  }

  .ant-collapse-item {}

  .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 10px 20px;
    padding-bottom: 20px;
  }

  .addres {
    background: white;
    padding: 30px;
    border-radius: 7px;
    border: 1px solid #C7C7C7;
  }
}


.n-user2 {
  border: 1px solid #BD44CA;
  padding: 10px 18px;
  border-radius: 30px;
  color: #BD44CA;
  font-weight: 600;
  font-size: 16px;
}

.ant-btn-primary {
  cursor: pointer;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #BD44CA;
  background: #BD44CA;
  margin-left: 8px;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 4px;
  /* 142.857% */
  padding: 23px 60px !important;
  align-items: center;


  .ant-modal .ant-modal-footer {
    text-align: center !important;
  }
}

.bg-gy2 {
  border-radius: 18px;
  border: 1px solid #C7C7C7;
  background: #F7F7F7;
  position: relative;

  width: 297px;
}

.seleted-product-list {
  // margin: -5px;
  // padding: 10px;
  border-radius: 25px;


}

.error {
  color: #c9190b;
  font-size: 14px;
}

.seleted-product-list .list {
  // background-color: #F1F1F1;
  min-height: 110px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px;
  background: #EAEAEA;
  padding-bottom: 10px;
  position: relative;

  &:last-child {

    &::before {
      display: none;
    }

  }

  // border-radius: 13px;
  // border-bottom: 1px solid #CCCCCC;
  &::before {
    content: "";
    width: 70%;
    bottom: 0%;
    position: absolute;
    left: 14%;
    height: 2px;
    display: inline-block;
    background-color: #CCCCCC;

  }

  label {
    position: absolute;
    top: -6px;
    // background-color: #fff;
    background-color: #EAEAEA;

    margin-left: 10px;


  }
}

.selected-product-list .list:last-child::before {
  content: none;
}

.peri-selected {
  stroke-width: 0.79px;
  stroke: #B5B5B5;
  font-size: 16px;
  height: 54px;
  // background: #fff;
  border: 1px solid #D9D9D9;
  background: inherit;
  // box-shadow: 0px 0px 47.5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.quto {
  background: #F7F7F7;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.quto-cent {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.new-user {
  input {
    background-color: #fff;
    height: 54px;
    font-size: 16px;
  }

  label {
    position: absolute;
    top: -6px;
    background-color: #fff;
    margin-left: 14px;
  }
}

.invite-user {
  cursor: pointer !important;

  label {
    position: absolute;
    top: -6px;
    background-color: #fff;
    margin-left: 14px;

  }
}


.ant-modal-body {
  iframe {
    width: 100%;
  }

}

.popup-doc {
  width: 1000px !important;
}

.priceTable {
  border-radius: 13px;
  border: 1px solid #C7C7C7;
  background: #FFF;

  th {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    background-color: white;

  }

  td {
    font-family: "Inter", sans-serif;
    font-weight: 400;
  }

  //  .total{
  //   border: 1px solid #C7C7C7;
  // border-radius: 0px 0px 12px 12px;
  //  }
}

.ant-pagination .ant-pagination-item-active {
  border-color: #BD44CA;

  &:hover {
    border-color: #BD44CA !important;
  }
}

.ant-pagination .ant-pagination-item-active a {
  color: #BD44CA;
  // border: 1px solid #BD44CA;
  // border-radius: 5px;

  &:hover {
    color: #BD44CA !important;

  }
}

.pop-color {
  color: black;
}

.letter-capital {
  text-transform: capitalize;
}

/* toastStyles.css */
.toast-container .Toastify__toast {
  color: black !important;
}

.toast-container .Toastify__toast-body {
  color: black !important;
}

// .toast-container .Toastify__toast--error .Toastify__toast-icon svg {
//   fill: #d9534f;
// }

.loader-akku {

  max-width: 60px;

}

.ant-modal .ant-modal-close {
  position: absolute;
  top: 2px !important;
  inset-inline-end: 17px;
  z-index: 1010;
  padding: 0;
  color: #BD44CA;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border-radius: 4px;
  width: 0px !important;
  height: 40px;
  border: 0;
  outline: 0;
  cursor: pointer;

  &:hover {
    color: #BD44CA;
  }

}

.ant-table-column-sorter-up.active {
  color: #000 !important;
}

.ant-table-column-sorter-down.active {
  color: #000 !important;
}

.dropdown-proposal {
  -webkit-appearance: none;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  border: 0px;
}

//scroll bar

//  * {
//    scrollbar-width: auto;
//    scrollbar-color: #A6A6A6;
//  }

//  /* Chrome, Edge, and Safari */
//  *::-webkit-scrollbar {
//    width: 5px;
//  }

//  ::-webkit-scrollbar {
//    width: 5px !important;
//    /* Vertical scrollbar width */
//    height: 4px !important;
//    /* Horizontal scrollbar height */
//  }

//  *::-webkit-scrollbar-track {
//    background: #FFFFFF;
//  }

//  *::-webkit-scrollbar-thumb {
//    background-color: #BD44CA;
//    border-radius: 10px;
//    border: 1px solid #E4E2E2;
//  }

//  .s-no{
// padding-left: 40px;
//  }

.ant-modal .ant-modal-title {
  color: #BD44CA;
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-with-icon .ant-notification-notice-message {
  font-weight: 500;
  font-size: 18px;
  color: black !important;
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-with-icon .ant-notification-notice-description {
  color: #B3B3B3 !important;
  font-size: 14px;
  font-weight: 500;
}

.cursor-role {
  cursor: pointer !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  cursor: pointer !important;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #C9C9C9;
  font-weight: 600;
  background-color: #fff;
}

// select[multiple]:focus option:checked {
//   background: red linear-gradient(0deg, red 0%, red 100%) !important;
// }

// option:checked {
//   color: #C9C9C9 !important;
// }

// option:checked:hover {
//   background: rgb(48, 8, 15) !important;
// }

// select[multiple]:focus option:hover {
//   background: #f5f5f5 !important;
// }


// .ant-pagination .ant-pagination-options {
//   display: inline-block;
//   /* margin-inline-start: 16px; */
//   vertical-align: middle;
// }


//page number
// .ant-pagination .ant-pagination-item {
//   min-width: 32px;
//   height: 32px;
//   margin-inline-end: 8px;
//   line-height: 30px;
//   text-align: center;
//   vertical-align: middle;
//   list-style: none;
//   background-color: transparent;
//   border: 1px solid transparent;
//   border-radius: 6px;
//   outline: 0;
//   cursor: pointer;
//   user-select: none;
// }

.ant-pagination .ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-inline-end: 8px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;

  border-radius: 6px;
  outline: 0;
  cursor: pointer;
  user-select: none;

  &:active {
    border: 1px solid #BD44CA;
  }
}


.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  line-height: 32px;

  border-radius: 6px;

  transition: all 0.2s;
}

.ant-pagination .ant-pagination-item a {
  font-size: 14px !important;
}

.ant-table-wrapper .ant-table-column-sorter-up,
.ant-table-wrapper .ant-table-column-sorter-down {
  font-size: 12px !important;
}

// .ant-table-wrapper .ant-table-tbody>tr>td{
//   padding: 16px 16px !important;
// }


//ant model invite edit delete
.ant-modal {
  width: 520px;
}

input[type=checkbox]:disabled {
  cursor: not-allowed;
}

.not-allowed {
  cursor: not-allowed;
}

.selected-row td {
  background-color: #F3F3F3 !important;

}

// --------------------------------------------------------------------media Query ------------------------------------------------------------------------


@media only screen and (max-width: 1700px) {
  .n-user1 {
    padding: 10px 15px;
    font-size: 17px;
  }

  .n-user2 {
    padding: 10px 15px;
    font-size: 17px;
  }
}

@media only screen and (max-width: 1500px) {
  .n-user1 {
    padding: 10px 13px;
    font-size: 16px;
  }

  .n-user2 {
    padding: 10px 13px;
    font-size: 16px;
  }

  .login-page {

    padding: 40px;


    h1 {

      font-size: 34px;
      padding-top: 80px;
      padding-bottom: 80px;

    }

    .google {
      width: 100%;
      height: 80px;
      font-size: 25px;
      padding: 20px;

      img {
        padding-right: 20px;
      }
    }

    .akku {
      width: 100%;
      height: 80px;
      font-size: 25px;
      padding: 20px;

      img {
        padding-right: 20px;
      }

    }
  }

  // .bg-gy1 label {

  //   font-size: 13px;
  // }

  // .frm1 input {

  //   font-size: 14px;
  //   background: #F7F7F7 !important;
  //   height: 50px;
  // }

  // #tran {
  //   font-size: 14px;
  //   height: 50px;
  // }

  // #peri {
  //   font-size: 14px;
  //   height: 50px;
  // }

  // .peri-selected {

  //   font-size: 14px;
  //   height: 50px;

  // }

  // .selected input {

  //   height: 50px;
  //   font-size: 14px;
  // }

  // .selected2 input {

  //   height: 50px;
  //   font-size: 14px;
  // }

  // .n-user1 {
  //   padding: 10px 11px;
  //   font-size: 15px;
  // }

  // .n-user2 {
  //   padding: 10px 9px;
  //   font-size: 15px;
  // }
}

// .dropdown-proposal option:checked {
//   background-color: #f5f5f5;
//   color: white;
// }



@media (max-width: 1440px) and (max-height: 810px) {
  label {

    font-size: 12px;
  }

  .frm1 input {

    font-size: 14px;
    background: #F7F7F7 !important;
    height: 50px;
  }

  #tran {
    font-size: 14px;
    height: 50px;
  }

  #peri {
    font-size: 14px;
    height: 50px;
  }

  .peri-selected {

    font-size: 14px;
    height: 50px;

  }

  .selected input {

    height: 50px;
    font-size: 14px;
  }

  .selected2 input {

    height: 50px;
    font-size: 14px;
  }

  .n-user1 {
    padding: 10px 11px;
    font-size: 15px;
  }

  .n-user2 {
    padding: 10px 9px;
    font-size: 15px;
  }

}

@media only screen and (max-width: 1300px) {
  .frm1 {
    position: relative;
    margin-top: 15px;

    input {
      height: 42px;
      font-size: 12px;
    }

    .lab1 {

      padding: 2px;
      right: 179px;
      top: -10px;
      font-size: 16px;

    }

    .lab {
      font-size: 12px;
    }

    .lab2 {
      font-size: 16px;
    }

    .lab3 {
      right: 160px;
      top: -10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      background: #fff;
    }
  }

  .accordian {
    .addres {
      padding: 15px;
    }
  }

  //login page

  .login-page {

    padding: 40px;


    h1 {

      font-size: 25px;
      padding-top: 50px;
      padding-bottom: 50px;


    }

    .google {
      width: 100%;
      height: 60.434px;
      font-size: 18px;
      padding: 15px;


      img {
        padding-right: 20px;
        width: 50px;
      }

    }

    .akku {
      width: 100%;
      height: 60.434px;
      font-size: 18px;
      padding: 15px;


      img {
        padding-right: 20px;
        width: 50px;
      }

    }
  }

  //nav

  .nav-name {
    font-size: 14px;
    line-height: 24px;

  }

  //log 
  h3 {
    font-size: 22px;
  }

  .int {
    width: 275px;
  }

  .n-user-newprop {
    margin-left: 8px;
    font-size: 10px;
    padding: 17px 3px;
    padding-right: 10px;
  }

  .bg-gy {
    padding: 17px;
  }

  .ant-table-wrapper .ant-table-cell,
  .ant-table-wrapper .ant-table-thead>tr>th,
  .ant-table-wrapper .ant-table-tbody>tr>th,
  .ant-table-wrapper .ant-table-tbody>tr>td,
  .ant-table-wrapper tfoot>tr>th,
  .ant-table-wrapper tfoot>tr>td {

    font-size: 12px !important;
    padding-left: 30px;
  }

  .bck {

    font-size: 14.462px;
    padding-left: 8px;
    padding-bottom: 10px;
    width: 100px;
  }

  //page number
  .ant-pagination .ant-pagination-item a {
    font-size: 10px !important;
  }

  //  .ant-pagination .ant-pagination-item {
  //    min-width: 25px;
  //    height: 25px;
  //    margin-inline-end: 4px;
  //    line-height: 24px;
  //  }
  .ant-pagination {
    margin: 10px 0 0 0 !important;

  }

  //  .ant-pagination-prev,
  //  .ant-pagination-next,
  //  .ant-pagination-jump-prev,
  //  .ant-pagination-jump-next {
  //   display: inline-block;
  //   min-width: 23px;
  //   height: 14px;
  //   color: rgba(0, 0, 0, 0.88);
  //   line-height: 24px;
  //   text-align: center;
  //   vertical-align: middle;
  //   list-style: none;
  //   border-radius: 6px;
  //   cursor: pointer;
  //   transition: all 0.2s;
  // }
  .ant-pagination .ant-pagination-prev,
  .ant-pagination .ant-pagination-next,
  .ant-pagination .ant-pagination-jump-prev,
  .ant-pagination .ant-pagination-jump-next {
    display: inline-block;
    min-width: 20px !important;
    height: 20px !important;
    line-height: 24px !important;
    border-radius: 6px;
    transition: all 0.2s;
  }

  .ant-pagination .ant-pagination-item {
    display: inline-block;
    min-width: 24px !important;
    height: 24px !important;
    margin-inline-end: 8px;
    line-height: 24px !important;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 6px;
    outline: 0;
    cursor: pointer;
    user-select: none;
  }

  //table
  .ant-table-content {
    height: calc(100vh - 250px);
    overflow-y: auto;
    padding-right: 2px;
    padding-top: 5px;
  }

  .ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 12px 32px;

  }

  .ant-table-wrapper .ant-table-column-sorter-up,
  .ant-table-wrapper .ant-table-column-sorter-down {
    font-size: 8px !important;
  }

  .n-user {
    margin-left: 5px;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 12px;
    padding-right: 15px !important;
  }


  //version history
  .bg-gy2 {
    width: 210px;
  }

  .version-history-title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .date {
    font-size: 11px;

  }

  .edi {
    font-size: 11px;
  }

  .tot {
    font-size: 11px
  }

  //new proposal
  h4 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 27px;
    margin-top: 10px;
  }

  label {
    color: #969696;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 16px */
  }

  #tran {
    font-size: 12px;
    height: 42px;
  }

  #peri {

    height: 42px;
    font-size: 12px;

  }

  .n-user1 {
    padding: 8px 8px;
    font-size: 13px;
  }

  .peri-selected {
    height: 42px;
    font-size: 12px;
  }

  .selected input {

    height: 42px;
    font-size: 12px;
  }

  .selected2 input {

    height: 42px;
    font-size: 12px;
  }

  .n-user2 {
    padding: 8px 10px;
    font-size: 12px;
  }

  //accordian  Quote
  .accordian .ant-collapse-header-text {
    font-size: 15px;
  }

  .frm {
    label {
      font-size: 11px;
    }

    input {
      font-size: 12px !important;
      height: 42px;
    }
  }

  .ant-select-selection-item {
    font-size: 12px;

  }

  .accordian .ant-collapse>.ant-collapse-item>.ant-collapse-header {

    padding: 15px;

  }

  //ant model invite edit delete
  .ant-modal {
    width: 424px !important;
  }

  .new-user input {
    background-color: #fff;
    height: 42px;
    font-size: 12px;
  }

  .rc-virtual-list .ant-select-item-option-content {
    font-size: 12px;
  }

  .dow1 {
    height: 40px;
    padding: 13px 37px;

  }

  .n-user3 {
    display: flex;
    width: 116px;
    height: 40px;
    padding: 13.23px 21.168px;
  }

  //toaster notification               
  .ant-notification .ant-notification-notice-wrapper .ant-notification-notice-with-icon .ant-notification-notice-message {
    font-weight: 500;
    font-size: 14px;
    color: black !important;
  }

  .ant-notification .ant-notification-notice-wrapper .ant-notification-notice-with-icon .ant-notification-notice-description {
    color: #B3B3B3 !important;
    font-size: 12px;
    font-weight: 500;
  }

  .ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
    padding: 16px 20px;
    width: 280px;
  }

  .cun {
    font-size: 11px;
  }

}

@media only screen and (max-width: 1030px) {
  .cal-col1 {
    grid-column: span 5 / span 5;
  }

  .cal-col2 {
    grid-column: span 7 / span 7;
  }
}


.ant-radio-checked .ant-radio-inner {
  border-color: #BD44CA !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #BD44CA;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #BD44CA;
  background-color: #BD44CA;
}

.ant-radio-checked .ant-radio-inner:focus {
  border-color: #BD44CA;
  background-color: #BD44CA;
}

.ant-radio-button-input:focus,
.ant-radio-button-inner {
  border-color: #BD44CA !important;
  color: #BD44CA !important;
  background-color: #BD44CA !important;
}

.ant-radio:hover,
.ant-radio-button-wrapper:hover {
  color: #BD44CA;
  background-color: #BD44CA !important;
}

.ant-radio-inner::after {
  background-color: #BD44CA !important;
}

.ant-radio-wrapper:hover {
  border-color: #BD44CA !important;
}

.ant-radio-wrapper:hover :where(.css-dev-only-do-not-override-1xg9z9n).ant-radio-wrapper,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #BD44CA !important;
}

.radioOptionLbl {
  font-size: 16px;
  font-weight: bold;
}

.rtoLable {
  label {
    position: unset;
  }
}

.ant-select-selection-search {
  width: 100% !important;
  min-height: 40px;
  padding: 5px;
}

.ant-select-selector {
  min-height: 55px !important;
}

.ant-select-arrow {
  user-select: none;
  width: 10px;
  color: #222222;
  font-weight: 500;
  padding-right: 15px;
}

.ant-select-selector {
  min-height: 54px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #222 !important;
}

.ant-select .ant-select-outlined {
  padding: 0px !important
}

.ant-select-selection-placeholder {
  padding-left: 5px;
}

.ant-select-outlined {
  padding: 0 !important;
  position: relative;
  z-index: 2;
}

.ant-select-show-search:where(.css-dev-only-do-not-override-1xg9z9n).ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  height: 54px !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-select .ant-select-arrow {
  margin-right: 20px;
  color: #000;
  font-weight: 600;
  font-size: 16px;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-select .ant-select-clear {
  margin-right: 20px;
  position: absolute;
  top: 50%;
  inset-inline-start: auto;
  inset-inline-end: 11px;
  z-index: 1;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  cursor: pointer;
  opacity: 0;
  transition: color 0.2s ease, opacity 0.3s ease;
  text-rendering: auto;
}

.ant-select-arrow {
  svg {
    height: 15px;
    width: 15px;
  }
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-select-multiple .ant-select-selector:after {
  display: inline-block;
  width: 0;
  margin: 10px 0;
  line-height: 24px;
  visibility: hidden;
  content: "\a0";
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") no-repeat right 18px center !important;
}

/* Disabled button style */
button.calculateBtn:disabled {
  background-color: #d3d3d3;
  /* Gray color for disabled state */
  color: #a9a9a9;
  /* Lighter color for text when disabled */
  cursor: not-allowed !important;
  /* Change cursor to indicate disabled state */
  opacity: 0.7;
}